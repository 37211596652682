import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project/project-preview-grid'
import UseCasePreviewGrid from '../components/usecase/usecase-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from '../lib/helpers'
import UspPreviewGrid from '../components/usp/usp-preview-grid'
import PagePreviewGrid from '../components/page/page-preview-grid'
import PageIntro from '../components/page/page-intro2'
import { PropTypes } from 'prop-types'
import { ContactModal } from '../components/contact/ContactModal'
import { Button } from '../components/Button/Button'
import { DemoPitch } from '../components/demoPitch'

export const query = graphql`
  query solutionsQuery {
    landing: allSanityLanding(
      filter: { slug: { current: { eq: "solution" } } }
    ) {
      edges {
        node {
          mainImage {
            asset {
              _id
            }
            alt
          }
        }
      }
    }

    index: sanityLanding(
      slug: { current: { eq: "solution" } }
      mainImage: { asset: {} }
    ) {
      id
      _id
      title
      excerpt
      subtitle
      mainImage {
        alt
        asset {
          _id
        }
      }
      _rawBody
      usps {
        title
        subTitle
        _rawExcerpt
        _rawBullets
        linkText
        linkUrl
        style {
          color
        }
        mainImage {
          alt
          asset {
            _id
          }
        }
      }
      pageentries {
        id
        title
        excerpt
        slug {
          current
        }
      }
      usecases {
        id
        mainImage {
          asset {
            _id
          }
          alt
        }
        title
        excerpt
        slug {
          current
        }
      }
    }

    projects: allSanityProject(
      limit: 12
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          mainImage {
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`
const SolutionsPage = (props) => {
  const { data, errors } = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const [showContactModal, setShowContactModal]= useState(false)
  const useCaseEntries = (data || {}).index.usecases
  const projectNodes =
    data &&
    data.projects &&
    mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
  const useCasesNodes =
    data &&
    data.useCases &&
    mapEdgesToNodes(data.useCases).filter(filterOutDocsWithoutSlugs)
  const landingContent =
    data &&
    data.landing &&
    mapEdgesToNodes(data.landing).filter(filterOutDocsWithoutSlugs)
  const pageNodes = (data || {})?.index?.pageentries
  const index = (data || {})?.index
  const uspNodes = (data || {})?.index?.usps
  const firstUsp = uspNodes?.slice(0, 1)
  const secondUsp = uspNodes?.slice(1, 5)
  return (

    <Layout backgroundColor={'#FFF9EE'} 
    >
      <SEO title='Solution' backgroundColor={'#FFF9EE'} />

      <PageIntro
        title={index.title}
        excerpt={index.excerpt}
        subtitle={index.subtitle}
        backgroundColor={'#FFF9EE'}
        showExcerpt={true}
        sansFont={true}
        backgroundImage={index.mainImage}
        product={true}
      />
      <Container >
        {firstUsp && firstUsp.length > 0 && <UspPreviewGrid nodes={firstUsp} />}
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{margin:'1rem'}}>
          <Button
            onClick={() => setShowContactModal(true)}
            buttonText='Book a demo'
          /></div>
        </div>
        {showContactModal && (
          <ContactModal onClose={() => setShowContactModal(false)} />
        )}
        {secondUsp && secondUsp.length > 0 && <UspPreviewGrid nodes={secondUsp} />}
      </Container>
      <DemoPitch />
      {useCaseEntries && useCaseEntries.length > 0 && (
        <Container backgroundColor='#EBF5F4' fullwidth={true}>

          <UseCasePreviewGrid title='Solutions' nodes={useCaseEntries} />

        </Container>
      )}
      <Container>
        {pageNodes && (
          <PagePreviewGrid title='' itemColor='white' pages={pageNodes} />
        )}
      </Container>
      {projectNodes && projectNodes.length > 0 &&
        <Container
          wide={true}
          backgroundColor="#F9F9F9"
          textColor="#1A1A1A"
        >
          <ProjectPreviewGrid nodes={projectNodes} title='Read more about our case studies' />
        </Container>
      }
    </Layout>
  )
}
SolutionsPage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
}

export default SolutionsPage
